// extracted by mini-css-extract-plugin
export var arrow = "Pagination-module--arrow--eZrdL";
export var currentPage = "Pagination-module--currentPage--h1WTN";
export var currentPageActive = "Pagination-module--currentPageActive--YHBBK";
export var disabled = "Pagination-module--disabled--xdAaj";
export var dots = "Pagination-module--dots--V50QG";
export var page = "Pagination-module--page--9CiG1";
export var pages = "Pagination-module--pages--UeTy6";
export var paginationItemContent = "Pagination-module--paginationItemContent--VKNSm";
export var root = "Pagination-module--root--TYUAp";
export var wrapper = "Pagination-module--wrapper--N-L+s";