import React from "react"
import { Link } from "gatsby"
import cx from "classnames"

import Container from "../../../ui-kit/Container/Container"
import Row from "../../../ui-kit/Row/Row"

import ArrowLeftDisabled from "../../../icons/light/arrows/arrow-left-disabled.svg"
import ArrowLeft from "../../../icons/light/arrows/arrow-left.svg"
import ArrowRightDisabled from "../../../icons/light/arrows/arrow-right-disabled.svg"
import ArrowRight from "../../../icons/light/arrows/arrow-right.svg"

import * as s from "./Pagination.module.sass"

const Pagination = ({
  parentPath,
  nextPage,
  previousPage,
  countOfPages,
  currentPage,
}) => {
  if (countOfPages <= 1) return null

  const pages = []
  if (countOfPages !== 1) {
    if (countOfPages <= 3) {
      for (let pageNumber = 1; pageNumber <= countOfPages; pageNumber += 1) {
        if (pageNumber === currentPage) {
          pages.push(
            <span key={pageNumber} className={cx(s.page, s.currentPage)}>
              <span className={s.currentPageActive}>{pageNumber}</span>
            </span>
          )
        } else if (pageNumber !== 1) {
          pages.push(
            <Link
              key={pageNumber}
              to={`${parentPath}/${pageNumber}`}
              className={s.page}
            >
              <span className={s.currentPageActive}>{pageNumber}</span>
            </Link>
          )
        } else {
          pages.push(
            <Link key={pageNumber} to={`${parentPath}`} className={s.page}>
              <span className={s.currentPageActive}>{pageNumber}</span>
            </Link>
          )
        }
      }
    } else {
      if (currentPage !== 1) {
        pages.push(
          <Link key={1} to={parentPath} className={s.page}>
            <span className={s.currentPageActive}>1</span>
          </Link>
        )
      }
      if (currentPage > 3) {
        pages.push(
          <span key={"other"} className={cx(s.page, s.dots)}>
            ...
          </span>
        )
      }
      if (currentPage >= 3) {
        pages.push(
          <Link
            key={currentPage - 1}
            to={`${parentPath}/${currentPage - 1}`}
            className={s.page}
          >
            <span className={s.currentPageActive}>{currentPage - 1}</span>
          </Link>
        )
      }
      pages.push(
        <span key={currentPage} className={cx(s.page, s.currentPage)}>
          <span className={s.currentPageActive}>
            <div className={s.paginationItemContent}>{currentPage}</div>
          </span>
        </span>
      )
      if (countOfPages - currentPage > 1) {
        pages.push(
          <Link
            key={currentPage + 1}
            to={`${parentPath}/${currentPage + 1}`}
            className={s.page}
          >
            <span className={s.currentPageActive}>{currentPage + 1}</span>
          </Link>
        )
      }
      if (countOfPages - currentPage > 2) {
        pages.push(
          <span key={"other+"} className={cx(s.page, s.dots)}>
            ...
          </span>
        )
      }
      if (currentPage !== countOfPages) {
        pages.push(
          <Link
            key={`countOfPages:${countOfPages}`}
            to={`${parentPath}/${countOfPages}`}
            className={s.page}
          >
            <span className={s.currentPageActive}>{countOfPages}</span>
          </Link>
        )
      }
    }
  }

  return (
    <div className={cx(s.root)}>
      <Container>
        <Row>
          <div className={s.wrapper}>
            {previousPage ? (
              <Link to={previousPage} className={s.arrow}>
                <img alt="Arrow left" src={ArrowLeft} />
              </Link>
            ) : (
              <span className={cx(s.arrow, s.disabled)}>
                <img src={ArrowLeftDisabled} alt="Arrow left(disabled)" />
              </span>
            )}
            <div className={s.pages}>{pages}</div>
            {nextPage ? (
              <Link to={nextPage} className={s.arrow}>
                <img alt="Arrow right" src={ArrowRight} />
              </Link>
            ) : (
              <span className={cx(s.arrow, s.disabled)}>
                <img alt="Arrow right(disabled)" src={ArrowRightDisabled} />
              </span>
            )}
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default Pagination
